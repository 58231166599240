export function showModal(message) {
  // Create overlay
  const overlay = document.createElement("div");
  overlay.style.position = "fixed";
  overlay.style.top = "0";
  overlay.style.left = "0";
  overlay.style.width = "100%";
  overlay.style.height = "100%";
  overlay.style.backgroundColor = "rgba(0, 0, 0, 0.7)";
  overlay.style.zIndex = "1000";
  overlay.style.display = "flex";
  overlay.style.justifyContent = "center";
  overlay.style.alignItems = "center";

  // Create modal
  const modal = document.createElement("div");
  modal.style.backgroundColor = "#fff";
  modal.style.padding = "20px";
  modal.style.boxShadow = "0 4px 8px rgba(0, 0, 0, 0.2)";
  modal.style.borderRadius = "8px";
  modal.style.maxWidth = "100%";
  modal.style.width = "100%";
  modal.style.textAlign = "center";
  modal.style.position = "relative";

  // Create modal text
  const modalText = document.createElement("h5");
  modalText.innerText = message;
  modal.appendChild(modalText);

  // Append modal to overlay
  overlay.appendChild(modal);

  // Append overlay to body
  document.body.appendChild(overlay);
}
