import React, { useEffect } from "react";
import "./App.css";
import { useTelegram } from "./hooks/useTelegram";
import { Route, Routes } from "react-router";
import Form from "./components/Form/Form";
import Rent from "./components/Rent/Rent";
import Support from "./components/Form/Support";
const App = () => {
  const { onToggleButton, tg } = useTelegram();
  useEffect(() => {
    tg.ready();
  }, []);

  return (
    <div className="App">
      <Routes>
        <Route path={"/form"} element={<Form />} />
        <Route path={"/rent"} element={<Rent />} />
        <Route path={"/support"} element={<Support />} />
      </Routes>
    </div>
  );
};

export default App;
