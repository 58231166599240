import React, {
  useState,
  useEffect,
  useCallback,
  useSyncExternalStore,
} from "react";
import { useForm } from "react-hook-form";
import Style from "../Form/Form.module.css";
import { useTelegram } from "../../hooks/useTelegram";
import {
  cityOrStreetForRent,
  cityOrStreetByRaion,
  regionsByCountry,
  raionByRegions,
  countries,
  ANIMAL,
  ROOMS,
} from "../config";
import axios from "axios";
import Dropdown from "../Dropdown/Dropdown";
import { SlArrowDown, SlArrowUp } from "react-icons/sl";
import { showModal } from "../Modal/Modal";
import images from "../assets";

const Rent = () => {
  const { tg } = useTelegram();
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    reset,
  } = useForm();
  const [selectedOption, setSelectedOption] = useState("Select Option");
  const [selectedCountry, setSelectedCountry] = useState("Виберіть країну");
  const [selectedRegion, setSelectedRegion] = useState("Виберіть область");
  const [selectedRaion, setSelectedRaion] = useState("Виберіть район/місто");
  const [selectedCityOrRaion, setSelectedCityOrRaion] = useState("");
  const [menu, setMenu] = useState(false);
  const [openRegion, setOpenRegion] = useState(false);
  const [openOption, setOpenOption] = useState(false);
  const [sellerName, setSellerName] = useState("");
  const [selectedAddr, setSelectedAddr] = useState("");
  const [selectedArea, setSelectedArea] = useState("");
  const [selectedFloor, setSelectedFloor] = useState("Виберіть поверх");
  const [openRaion, setOpenRaion] = useState(false);
  const [openCityOrRaion, setOpenCityOrRaion] = useState(false);
  const [isAnimal, setIsAnimal] = useState(false);
  const [isOwner, setIsOwner] = useState(false);
  const [selectedAnimal, setSelectedAnimal] = useState("Виберіть відповідь");
  const [openAnimalMenu, setOpenAnimalMenu] = useState(false);
  const [openRoomMenu, setOpenRoomMenu] = useState(false);
  const [openFloorMenu, setOpenFloorMenu] = useState(false);
  const [selectedRooms, setSelectedRooms] = useState(1);
  const [userDescription, setUserDescription] = useState("");
  const [userPrice, setUserPrice] = useState("");
  const [selectedOnwer, setSelectedOwner] = useState("Виберіть відповідь");
  const [ownerMenu, setOwnerMenu] = useState(false);
  const getMaster = false;
  const rentApartment = true;
  const isVisible = true;
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [dataUser, setDataUser] = useState(null);
  const [isForBuy, setisForBuy] = useState(false);
  const [selectedType, setSelectedType] = useState("Виберіть відповідь");
  const [openTypeMenu, setOpenTypeMenu] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const appUrl = process.env.REACT_APP_API_URL;

  //console.log("appUrl", appUrl);
  const FLOORS = Array.from({ length: 20 }, (_, i) => (i + 1).toString());
  // const [masterOptions, setMasterOptions] = useState({
  //   external: [],
  //   internal: [],
  //   complex: [],
  // });

  // const toggleOption = (prev, option) => {
  //   if (prev.includes(option)) {
  //     return prev.filter((item) => item !== option);
  //   } else {
  //     return [...prev, option];
  //   }
  // };

  // const toggleSubOption = (option) => {
  //   switch (masterOption) {
  //     case 'external':
  //       setSelectedSubOptionExternal((prev) => toggleOption(prev, option));
  //       break;
  //     case 'internal':
  //       setSelectedSubOptionInternal((prev) => toggleOption(prev, option));
  //       break;
  //     case 'complex':
  //       setSelectedSubOptionComplex((prev) => toggleOption(prev, option));
  //       break;
  //     default:
  //       break;
  //   }
  // };
  // const handleSubOption = () => {
  //   setListVisible(!isListVisible);
  // };

  // const handleMaster = () => {
  //   setSelectedWorkOption(true);
  // };

  // const handleMasterOption = (e) => {
  //   setMasterOption(e.target.getAttribute('data-value'));
  //   setSelectedWorkOption(false);
  //   setMaster(true);
  // };
  // const handleOption = () => {
  //   setOpenOption(!openOption);
  // };

  // const handleOptionList = (e) => {
  //   const selectedValue = e.target.getAttribute('data-value');
  //   setSelectedOption(e.target.getAttribute('data-value'));
  //   setOpenOption(false);
  //   setSelectedWorkOption(true);
  //   setValue('option', selectedValue);
  // };
  const openMenu = () => {
    setMenu(!menu);
  };

  // useEffect(() => {
  //   console.log("ISFORBUY", isForBuy);
  // }, [isForBuy]);

  function getUrlParams() {
    const params = new URLSearchParams(window.location.search);
    const chatId = params.get("chatId");
    // Extract tgname

    //return { chatId};
    return chatId;
  }
  function getUrlTgname() {
    const params = new URLSearchParams(window.location.search);
    const tgname = params.get("tgname") || "N/A";
    return tgname;
  }

  async function sendChatIdToBackend(chatId) {
    try {
      // GET request to send chatId as a query parameter
      const response = await axios.post(`${appUrl}/api/logChatId`, {
        chatId: chatId,
      });
      console.log("response", response.data);
      // Process the response data
      //const userState = response.data;
      setDataUser(response.data);
    } catch (error) {
      console.error("Error sending chat ID or receiving data:", error);
    }
  }

  useEffect(() => {
    const chatId = getUrlParams();
    const tgname = getUrlTgname();
    //console.log("TGNAME", tgname);
    if (chatId && tgname !== "N/A") {
      //console.log("Sending chatId to backend:", chatId);
      sendChatIdToBackend(chatId);
    } else {
      showModal(
        `Щоб почати виставляти оголошення,  додайте ім'я користувача(username) в налаштування телеграм, мій аккаунт і введіть назву, після того перезавантажнте бота командою "/start" по кнопці "Menu". `
      );
      //console.log("Chat ID not found!");
    }
  }, []);

  useEffect(() => {
    if (dataUser) {
      //console.log("dataUser updated:", dataUser);
      // console.log(
      //   "paymentStatus",
      //   dataUser.paymentStatus,
      //   "counter",
      //   dataUser.counter
      // );
      //setUserDescription(dataUser);
      if (dataUser.paymentStatus == false && dataUser.counter === 1) {
        //showModal(`Оплата успішна`);
        //setIsModalOpen(true);
        showModal(
          `Ваш пробний період завершився. Щоб продовжити виставляти оголошення, виберіть відповідний тариф у головному меню по кнопці '💼 Тарифний план' і здійсніть оплату.`
        );
      }
      if (
        dataUser.paymentStatus == true &&
        dataUser.counter === 5 &&
        dataUser.amount === 300
      ) {
        showModal(
          `Ваш тарифний план завершився. Щоб продовжити виставляти оголошення, виберіть відповідний тариф у головному меню по кнопці '💼 Тарифний план' і здійсніть оплату.`
        );
      }
      if (
        dataUser.paymentStatus == true &&
        dataUser.counter === 10 &&
        dataUser.amount === 500
      ) {
        showModal(
          `Ваш тарифний план завершився. Щоб продовжити виставляти оголошення, виберіть відповідний тариф у головному меню по кнопці '💼 Тарифний план' і здійсніть оплату.`
        );
      }
      if (
        dataUser.paymentStatus == true &&
        dataUser.counter === 20 &&
        dataUser.amount === 750
      ) {
        showModal(
          `Ваш тарифний план завершився. Щоб продовжити виставляти оголошення, виберіть відповідний тариф у головному меню по кнопці '💼 Тарифний план' і здійсніть оплату.`
        );
      }
    }
  }, [dataUser]);

  const openOnwerMenu = () => {
    setOwnerMenu(!ownerMenu);
  };

  const handleMenu = (country) => {
    setSelectedCountry(country);
    setValue("country", country);
    setOpenRegion(true);
    setMenu(false); // Close the dropdown after selecting an option
  };

  const handleRegionMenu = (region) => {
    setSelectedRegion(region);
    setValue("region", region);
    setOpenRegion(false);
    setOpenRaion(true);
  };

  const handleRaionMenu = (raion) => {
    setSelectedRaion(raion);
    setValue("raion", raion);
    setOpenRaion(false);
    setOpenCityOrRaion(true);
  };

  const handleCityOrRaion = (cityOrRaion) => {
    // setSelectedCityOrRaion((prevState) => {
    //   const newItems = prevState.includes(cityOrRaion)
    //     ? prevState.filter((item) => item !== cityOrRaion)
    //     : [...prevState, cityOrRaion];
    //   return newItems;
    // });

    setSelectedCityOrRaion(cityOrRaion);
    setOpenCityOrRaion(false);
    //setValue('cityOrRaion');
    //setOpenCityOrRaion(false);
    //setOpenRoomMenu(true);
    setOpenTypeMenu(true);
  };

  const handleAnimalMenu = (props) => {
    setSelectedAnimal(props);
    setIsAnimal(props === "Yes");
    setOpenAnimalMenu(false);
    setOwnerMenu(true);
  };

  const handleFloorMenu = (props) => {
    setSelectedFloor(props);
    setOpenFloorMenu(false);
    setOpenRoomMenu(true);
  };

  const handleOnwerMenu = (props) => {
    setSelectedOwner(props);
    setIsOwner(props === "Yes");
    setOwnerMenu(false);
  };

  const handleRoomMenu = (room) => {
    // setSelectedRooms((prevState) => {
    //   const newItems = prevState.includes(room)
    //     ? prevState.filter((item) => item !== room)
    //     : [...prevState, room];
    //   return newItems;
    // });
    setSelectedRooms(room);
    setOpenRoomMenu(false);
    setOpenAnimalMenu(true);
  };

  const handleNameChange = (e) => {
    const value = e.target.value;
    //console.log("sellerName", sellerName);
    setSellerName(value);
    localStorage.setItem("sellerName", value);
  };

  // const handleFileUpload = (e) => {
  //   const files = e.target.files;
  //   setUploadedFiles((prevFiles) => [...prevFiles, ...files]); // Append new files to existing ones
  //   console.log(uploadedFiles, "uploadedFiles");
  // };
  // const handleFileUpload = (e) => {
  //   const file = e.target.files[0];

  //   if (file) {
  //     // Process the file, e.g., upload it to the server or display a preview
  //     console.log("Selected file:", file);
  //   }
  // };
  // const convertFilesToBase64 = (files) => {
  //   return Promise.all(
  //     files.map((file) => {
  //       return new Promise((resolve, reject) => {
  //         const reader = new FileReader();
  //         reader.onload = () => resolve(reader.result);
  //         reader.onerror = (error) => reject(error);
  //         reader.readAsDataURL(file);
  //       });
  //     })
  //   );
  // };

  const handleisForBuyMenu = (props) => {
    setSelectedType(props);
    setisForBuy(props === "Yes");
    setOpenTypeMenu(false);
    //setOpenAnimalMenu(true);
    //setOpenRoomMenu(true);
  };

  const onSendData = useCallback(async () => {
    const data = {
      selectedCountry,
      selectedRegion,
      selectedRaion,
      sellerName,
      userPrice,
      selectedCityOrRaion,
      selectedRooms,
      selectedFloor,
      selectedAddr,
      selectedArea,
      isAnimal,
      isOwner,
      isVisible,
      userDescription,
      getMaster,
      rentApartment,
      isForBuy,
    };

    try {
      console.log("Sending data:", data); // Debugging line
      tg.sendData(JSON.stringify(data));
      console.log("Data sent successfully");
      //axios.post('https://4577-188-191-42-31.ngrok-free.app/web-data', data);
      //console.log('Data sent successfully');
    } catch (error) {
      console.error("Error submitting form:", error);
      // Handle error if needed
    }
  }, [
    selectedCountry,
    selectedRegion,
    selectedRaion,
    sellerName,
    userPrice,
    selectedCityOrRaion,
    selectedRooms,
    isAnimal,
    isOwner,
    isVisible,
    userDescription,
    getMaster,
    selectedFloor,
    selectedAddr,
    selectedArea,
    rentApartment,
    isForBuy,
  ]);

  useEffect(() => {
    tg.onEvent("mainButtonClicked", onSendData);
    return () => {
      tg.offEvent("mainButtonClicked", onSendData);
    };
  }, [onSendData]);

  useEffect(() => {
    tg.MainButton.setParams({
      text: "Send data",
    });
  }, []);

  useEffect(() => {
    if (
      selectedCountry !== "Виберіть країну" &&
      selectedRegion !== "Виберіть область" &&
      selectedCityOrRaion !== "Виберіть місто/район" &&
      selectedRooms !== "Виберіть кількість кімнат" &&
      (selectedAnimal !== "Виберіть відповідь" || isForBuy == true) &&
      userDescription !== "" &&
      sellerName.length >= 3 &&
      userPrice !== "" &&
      selectedFloor !== "Виберіть поверх" &&
      selectedAddr !== "" &&
      selectedArea !== "" &&
      selectedType !== "Виберіть відповідь"
    ) {
      tg.MainButton.show();
    } else {
      tg.MainButton.hide();
    }
  }, [
    selectedCountry,
    selectedRegion,
    selectedRaion,
    selectedCityOrRaion,
    selectedAnimal,
    selectedRooms,
    userDescription,
    sellerName,
    userPrice,
    selectedFloor,
    selectedArea,
    selectedAddr,
    selectedType,
  ]);

  // useEffect(() => {
  //   console.log("typeof price", typeof userPrice);
  // }, [userPrice]);

  useEffect(() => {
    if (selectedRaion) {
      setSelectedCityOrRaion([]);
    }
  }, [selectedRaion]);

  useEffect(() => {
    const storedName = localStorage.getItem("sellerName");

    if (storedName) {
      setSellerName(storedName);
    }
  }, []);

  useEffect(() => {
    // Simulate a delay for loading
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 2000); // Replace with actual data fetching or API call logic

    return () => clearTimeout(timer); // Cleanup the timeout
  }, []);

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "100vh",
          //backgroundColor: "#f0f0f0", // Optional: background color for better visibility
        }}
      >
        <img
          src={images.loader}
          alt="Loading..."
          style={{ width: "100px", height: "100px" }} // Adjust size as needed
        />
        {/* <h5 className="marquee-text" style={{ textAlign: "center" }}>
          ��������� ���� ������� �������������
          <p>Please wait while the page is loading</p>
        </h5> */}
      </div>
    );
  }
  return (
    <div
      className={Style.Forma}
      // style={{ borderRadius: "15px", overflow: "hidden" }}
    >
      <form>
        {/* <h1>Виставити квартиру/дім | Rent an apartment 🏘</h1> */}

        <div className="marquee-container">
          <h1 className="marquee-text">
            🔑Виставити об'єкт нерухомості | Rent an apartment 🏡
          </h1>
        </div>
        <label>
          <h3>👤 Ваше ім'я | Your name</h3>
          <input
            type="text"
            value={sellerName}
            onChange={handleNameChange}
            style={{ color: "black", fontWeight: "bold" }}
          />
        </label>

        <Dropdown
          label="🌍 Країна | Country"
          value={selectedCountry}
          items={menu ? countries : null}
          onToggle={openMenu}
          onSelect={handleMenu}
          isOpen={menu}
        />

        <Dropdown
          label="🌆 Область | Region"
          value={selectedRegion}
          items={
            openRegion && selectedCountry !== "Виберіть країну"
              ? regionsByCountry[selectedCountry]
              : null
          }
          onToggle={() => setOpenRegion(!openRegion)}
          onSelect={handleRegionMenu}
          isOpen={openRegion}
        />

        <Dropdown
          label="🏙️ Район/Місто | District/City"
          value={selectedRaion}
          items={
            openRaion && selectedRegion !== "Виберіть область"
              ? raionByRegions[selectedRegion]
              : null
          }
          onToggle={() => setOpenRaion(!openRaion)}
          onSelect={handleRaionMenu}
          isOpen={openRaion}
        />
        <label>
          <h3>🏠 Місто/Райони | City/District</h3>
          <div
            className="custom-dropdown"
            {...register("cityOrStrt", {
              required: "The city is mandatory",
            })}
          >
            <div
              className="dropdown-button"
              onClick={() => setOpenCityOrRaion(!openCityOrRaion)}
            >
              <h4>
                {" "}
                {selectedCityOrRaion.length > 0
                  ? selectedCityOrRaion
                  : "Виберіть місто/райони"}
                {openCityOrRaion ? (
                  <SlArrowUp
                    style={{
                      float: "right",
                      color: "black",
                      marginTop: "10px",
                    }}
                  />
                ) : (
                  <SlArrowDown
                    style={{
                      float: "right",
                      color: "black",
                      marginTop: "10px",
                    }}
                  />
                )}
              </h4>
            </div>
            {openCityOrRaion && selectedRaion !== "Виберіть район/місто" && (
              <div className="dropdown-list">
                {cityOrStreetForRent[selectedRaion].map((city, index) => (
                  <div
                    key={index}
                    className="dropdown-item"
                    onClick={() => handleCityOrRaion(city)}
                  >
                    <h4>{city}</h4>
                  </div>
                ))}
              </div>
            )}
          </div>
        </label>
        <Dropdown
          label="🔑 Bиставляєте на продаж? | You put it up for sale ?"
          value={selectedType}
          items={
            openTypeMenu && selectedRaion !== "Виберіть місто/район"
              ? ANIMAL
              : null
          }
          onToggle={() => setOpenTypeMenu(!openTypeMenu)}
          onSelect={handleisForBuyMenu}
          isOpen={openTypeMenu}
        />

        <label>
          <h3>🏡 Адреса | Address</h3>
          <input
            type="text"
            value={selectedAddr}
            onChange={(e) => setSelectedAddr(e.target.value)}
            style={{ color: "black", fontWeight: "bold" }}
          />
        </label>
        <label>
          <h3>📐 Площа квартири | Area</h3>
          <input
            type="number"
            value={selectedArea}
            onChange={(e) => setSelectedArea(e.target.value)}
            style={{ color: "black", fontWeight: "bold" }}
          />
        </label>
        <Dropdown
          label="🏢 Поверх | Floor"
          value={selectedFloor}
          items={
            openFloorMenu && selectedRaion !== "Виберіть місто/район"
              ? FLOORS
              : null
          }
          onToggle={() => setOpenFloorMenu(!openFloorMenu)}
          onSelect={handleFloorMenu}
          isOpen={openFloorMenu}
        />
        <Dropdown
          label="🛏️ Кількість кімнат | Number of rooms"
          value={selectedRooms}
          items={
            openRoomMenu && selectedRaion !== "Виберіть місто/район"
              ? FLOORS
              : null
          }
          onToggle={() => setOpenRoomMenu(!openRoomMenu)}
          onSelect={handleRoomMenu}
          isOpen={openRoomMenu}
        />

        {selectedType !== "Yes" && (
          <Dropdown
            label="🐾 Можна з твариною? | Is it possible with an animal?"
            value={selectedAnimal}
            items={
              openAnimalMenu && selectedRaion !== "Виберіть місто/район"
                ? ANIMAL
                : null
            }
            onToggle={() => setOpenAnimalMenu(!openAnimalMenu)}
            onSelect={handleAnimalMenu}
            isOpen={openAnimalMenu}
          />
        )}

        {/* <Dropdown
          label="Можна з твариною? | Is it possible with an animal?"
          value={selectedAnimal}
          items={
            openAnimalMenu && selectedRaion !== "Виберіть місто/район"
              ? ANIMAL
              : null
          }
          onToggle={() => setOpenAnimalMenu(!openAnimalMenu)}
          onSelect={handleAnimalMenu}
          isOpen={openAnimalMenu}
        /> */}

        <Dropdown
          label="👤 Ви власник ? | Are you the owner ?"
          value={selectedOnwer}
          items={ownerMenu ? ANIMAL : null}
          onToggle={openOnwerMenu}
          onSelect={handleOnwerMenu}
          isOpen={ownerMenu}
        />

        <label>
          <h3>
            {selectedType !== "Yes"
              ? "💵Сума оренди за місяць | The amount of rent per month"
              : "💵Ціна | Price"}
          </h3>
          <input
            type="number"
            placeholder="Введіть ціну у USD | Enter the price in USD "
            onChange={(e) => setUserPrice(e.target.value)}
            style={{ color: "black", fontWeight: "bold" }}
          />
        </label>

        <h3>📝 Опис: | Description:</h3>

        <textarea
          style={{
            height: "100px",
            width: "100%",
            fontWeight: "bold",
            fontSize: "16px",
          }}
          placeholder="Введіть опис обєкта нерухомості | Enter a description of the apartment. "
          onChange={(e) => setUserDescription(e.target.value)}
        />
        {/* <input
          type="file"
          accept="image/*"
          onChange={(e) => handleFileUpload(e)}
        /> */}
      </form>
      <div style={{ height: "10px" }}></div>
    </div>
  );
};

export default Rent;
