import React, { useState } from "react";
import { SlArrowDown, SlArrowUp } from "react-icons/sl";
const Dropdown = ({ label, value, items, onToggle, onSelect, isOpen }) => {
  const [visibleItems, setVisibleItems] = useState(6); // Set the number of visible items

  return (
    <label>
      <h3>{label}</h3>
      <div className="custom-dropdown">
        <div className="dropdown-button" onClick={onToggle}>
          <h4>
            {value}
            {isOpen ? (
              <SlArrowUp
                style={{ float: "right", color: "black", marginTop: "10px" }}
              />
            ) : (
              <SlArrowDown
                style={{ float: "right", color: "black", marginTop: "10px" }}
              />
            )}
          </h4>
        </div>
        {items && (
          <div
            className="dropdown-list"
            style={{ maxHeight: `${visibleItems * 40}px`, overflowY: "auto" }}
          >
            {items.map((item, index) => (
              <div
                key={index}
                className="dropdown-item"
                onClick={() => onSelect(item)}
              >
                <h4>{item}</h4>
              </div>
            ))}
          </div>
        )}
      </div>
    </label>
  );
};

export default Dropdown;
