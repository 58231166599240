const countries = ["Ukraine"];
//const countries = ['USA', 'Canada', 'UK', 'Ukraine', 'Poland', 'India'];
const raionByRegions = {
  Lvivska_Oblast: [
    "Lviv",
    "Chervonohrad_Raion",
    "Drohobych_Raion",
    "Sambir_Raion",
    "Yavoriv_Raion",
    "Zolochiv_Raion",
  ],
  Kyivska_Oblast: ["Kyiv", "Boryspil", "Fastiv"],
  Kharkivska_Oblast: ["Kharkiv"],
  Odeska_Oblast: ["Odesa", "Bilhorod_Dnistrovskyi", "Yuzhne"],
  Dnipropetrovska_Oblast: ["Dnipro", "Kryvyi_Rih", "Kamianske"],
  Vinnytska_Oblast: ["Vinnytsia", "Khmilnyk", "Brodky"],
  Poltavska_Oblast: ["Poltava", "Kremenchuk", "Mirhorod"],
  Chernihivska_Oblast: ["Chernihiv", "Nizhyn", "Novhorod_Siverskyi"],
  Cherkaska_Oblast: ["Cherkasy", "Uman", "Zolotonosha"],
  Khmelnytska_Oblast: ["Khmelnytskyi", "Kamianets_Podilskyi", "Netishyn"],
  Chernivetska_Oblast: ["Chernivtsi", "Kelmentsi", "Khotyn"],
  Sumska_Oblast: ["Sumy", "Shostka", "Konotop"],
  Rivnenska_Oblast: ["Rivne", "Dubno", "Zdolbuniv"],
  IvanoFrankivska_Oblast: ["Ivano_Frankivsk", "Kolomyia", "Nadvirna"],
  Kirovohradska_Oblast: ["Kropyvnytskyi", "Oleksandriia", "Svitlovodsk"],
  Ternopilska_Oblast: ["Ternopil", "Chortkiv", "Pidhaitsi"],
  Volynska_Oblast: ["Lutsk", "Kovel", "Turiyisk"],
  Zakarpatska_Oblast: ["Uzhhorod", "Mukachevo", "Khust"],
};
const cityOrStreetByRaion = {
  Lviv: [
    "All",
    "Shevchenkivskyi",
    "Lychakivsky",
    "Sykhiv",
    "Frankivskyi",
    "Zaliznychnyy",
    "Halytskyi",
  ],
  Chervonohrad_Raion: ["Sokal", "Chervonohrad", "Zhovka"],
};

const cityOrStreetForRent = {
  Lviv: [
    "Shevchenkivskyi",
    "Lychakivsky",
    "Sykhiv",
    "Frankivskyi",
    "Zaliznychnyy",
    "Halytskyi",
    "All",
  ],
  Chervonohrad_Raion: ["Sokal", "Chervonohrad", "Zhovka"],
  Kyiv: [
    "Darnytskyi",
    "Desnianskyi",
    "Dniprovskyi",
    "Holosiivskyi",
    "Obolonskyi",
    "Pecherskyi",
    "Podilskyi",
    "Shevchenkivskyi",
    "Solomianskyi",
    "Sviatoshynskyi",
    "All",
  ],
  Khmelnytskyi: [
    "Ozerna",
    "Rakove",
    "Lezneve",
    "Dubove",
    "Grechany",
    "Vystavka",
    "Ruzhichna",
    "Knyzhkivtsi",
    "Pivdenno_Zakhidnyi",
    "All",
  ],
  Kharkiv: [
    "Shevchenkivskyi",
    "Kholodnohirskyi",
    "Novobavarskyi",
    "Osnovianskyi",
    "Slobidskyi",
    "Moskovskyi",
    "Industrialnyi",
    "Saltivskyi",
    "All",
  ],
  Odesa: ["Malynovskyi", "Kyivskyi", "Suvorovskyi", "Prymorskyi", "All"],
  Dnipro: [
    "Amur_Nyzhnodniprovskyi",
    "Chechelivskyi",
    "Industrialnyi",
    "Sobornyi",
    "Shevchenkivskyi",
    "Samarskyi",
    "Tsentralnyi",
    "All",
  ],
  Zaporizhzhia: [
    "Dniprovskyi",
    "Oleksandrivskyi",
    "Komunarskyi",
    "Voznesenivskyi",
    "Khortytskyi",
    "Shevchenkivskyi",
    "All",
  ],
  Drohobych_Raion: ["Drohobych"],
  Sambir_Raion: ["Sambir", "Rudky"],
  Yavoriv_Raion: ["Yavoriv"],
  Zolochiv_Raion: ["Zolochiv"],
  Mykolaiv: ["Inhulskyi", "Korabelnyi", "Tsentralnyi", "Zavodskyi", "All"],
  Vinnytsia: ["Zamostianskyi", "Vyshenska", "Tsentralnyi", "All"],
  Cherkasy: ["Pivdenno_Zakhidnyi", "Sosnivskyi"],
  Chernihiv: ["Desnianskyi", "Novozavodskyi"],
  Ivano_Frankivsk: ["Ivano_Frankivsk"],
  Poltava: ["Podilskyi", "Kyivskyi", "Shevchenkivskyi"],
  Sumy: ["Zarichnyi", "Kovpakivskyi"],
  Ternopil: ["Ternopil"],
  Uzhhorod: ["Uzhhorod"],
  Zhytomyr: ["Bohuniivskyi", "Koroliovskyi"],
  Boryspil: ["Boryspil"],
  Fastiv: ["Fastiv"],
  Yuzhne: ["Yuzhne"],
  Bilhorod_Dnistrovskyi: ["Bilhorod_Dnistrovskyi"],
  KryvyiRih: ["Kryvyi_Rih"],
  Kamianske: ["Kamianske"],
  Khmilnyk: ["Khmilnyk"],
  Brodky: ["Brodky"],
  Kremenchuk: ["Kremenchuk"],
  Mirhorod: ["Mirhorod"],
  Nizhyn: ["Nizhyn"],
  Novhorod_Siverskyi: ["Novhorod_Siverskyi"],
  Uman: ["Uman"],
  Zolotonosha: ["Zolotonosha"],
  Kamianets_Podilskyi: ["Kamianets_Podilskyi"],
  Netishyn: ["Netishyn"],
  Chernivtsi: ["Chernivtsi"],
  Kelmentsi: ["Kelmentsi"],
  Khotyn: ["Khotyn"],
  Shostka: ["Shostka"],
  Konotop: ["Konotop"],
  Rivne: ["Rivne"],
  Dubno: ["Dubno"],
  Zdolbuniv: ["Zdolbuniv"],
  Kolomyia: ["Kolomyia"],
  Nadvirna: ["Nadvirna"],
  Kropyvnytskyi: ["Kropyvnytskyi"],
  Oleksandriia: ["Oleksandriia"],
  Svitlovodsk: ["Svitlovodsk"],
  Chortkiv: ["Chortkiv"],
  Pidhaitsi: ["Pidhaitsi"],
  Lutsk: ["Lutsk"],
  Kovel: ["Kovel"],
  Turiyisk: ["Turiyisk"],
  Mukachevo: ["Mukachevo"],
  Khust: ["Khust"],
};

const regionsByCountry = {
  Ukraine: [
    "Kyivska_Oblast",
    "Lvivska_Oblast",
    "Kharkivska_Oblast",
    "Odeska_Oblast",
    "Dnipropetrovska_Oblast",
    "Vinnytska_Oblast",
    "Poltavska_Oblast",
    "Chernihivska_Oblast",
    "Cherkaska_Oblast",
    "Khmelnytska_Oblast",
    "Chernivetska_Oblast",
    "Sumska_Oblast",
    "Rivnenska_Oblast",
    "IvanoFrankivska_Oblast",
    "Kirovohradska_Oblast",
    "Ternopilska_Oblast",
    "Volynska_Oblast",
    "Zakarpatska_Oblast",
  ],
  // USA: ['New York', 'Los Angeles', 'Chicago'],
  // Canada: ['Toronto', 'Vancouver', 'Montreal'],
  // UK: ['London', 'Manchester', 'Edinburgh'],
  // Poland: ['Warsaw', 'Krakow'],
  // India: ['Achhnera', 'Ghoti Budruk'],
};

const ANIMAL = ["Yes", "No"];

const ROOMS = ["1", "2", "3", "4", "5"];

////////////////////////////////////////////////////////////////////

const carBrands = [
  "Acura",
  "Alfa Romeo",
  "Aston Martin",
  "Audi",
  "Bentley",
  "BMW",
  "Bugatti",
  "Buick",
  "BYD",
  "Cadillac",
  "Chevrolet",
  "Chrysler",
  "Citroën",
  "Dacia",
  "Daewoo",
  "Dodge",
  "DS",
  "Ferrari",
  "Fiat",
  "Ford",
  "GMC",
  "Honda",
  "Hyundai",
  "Infiniti",
  "Isuzu",
  "Jaguar",
  "Jeep",
  "Kia",
  "Lamborghini",
  "Land Rover",
  "Lexus",
  "Maserati",
  "Mazda",
  "Mercedes-Benz",
  "Mini",
  "Mitsubishi",
  "Nissan",
  "Opel",
  "Peugeot",
  "Porsche",
  "Renault",
  "Rolls-Royce",
  "SEAT",
  "Škoda",
  "Smart",
  "Subaru",
  "Suzuki",
  "Tesla",
  "Toyota",
  "Volkswagen",
  "Volvo",
];

const carModels = {
  Acura: ["RL", "RLX", "RSX", "TL", "TLX", "TSX", "ZDX"],
  "Alfa Romeo": [
    "146",
    "147",
    "155",
    "156",
    "159",
    "164",
    "166",
    "33",
    "4C",
    "75",
    "90",
    "Brera",
    "Giulia",
    "Giulietta",
    "GT",
    "MiTo",
    "Spider",
    "Stelvio",
    "Tonale",
  ],
  "Aston Martin": [
    "DB9",
    "DBX",
    "Rapide",
    "Vantage",
    "Vulcan",
    "DB11",
    "DB9",
    "DBS Superleggera",
    "DBX",
    "Rapide",
    "Vantage",
    "Vulcan",
  ],
  Audi: [
    "5000",
    "80",
    "A3",
    "A4",
    "Allroad",
    "A5",
    "A6",
    "A7",
    "Sportback",
    "A8",
    "e-tron",
    "Q3",
    "Q5",
    "Q7",
    "Q8",
    "RS6",
    "S4",
    "S6",
    "TT",
    "100",
    "200",
    "50",
    "75",
    "90",
    "A1",
    "Citycarver",
    "A2",
    "Coupe",
    "GT",
    "S",
    "Q2",
    "Q2L",
    "Q4",
    "R8",
    "RS",
    "RS3",
    "RS4",
    "RS5",
    "RS7",
    "S3",
    "S5",
    "S7",
    "S8",
    "SQ5",
    "SQ7",
    "SQ8",
    "V8",
  ],
  Bentley: [
    "Arnage",
    "Bentayga",
    "Continental",
    "Continental GT",
    "Flying Spur",
    "Flying Spur V8",
    "Mulsanne",
  ],
  BMW: [
    "2",
    "Series",
    "Active",
    "Tourer",
    "3",
    "GT",
    "4",
    "Gran",
    "Coupe",
    "5",
    "6",
    "7",
    "I3",
    "iX",
    "M3",
    "M4",
    "M5",
    "X1",
    "X3",
    "X4",
    "X5",
    "M",
    "X6",
    "X7",
    "iX1",
    "1",
    "1800",
    "Compact",
    "8",
    "E3",
    "E9",
    "i3S",
    "i4",
    "i5",
    "i7",
    "i8",
    "iX3",
    "M2",
    "M6",
    "M8",
    "Neue",
    "Klasse",
    "X",
    "X2",
    "XM",
    "Z",
    "Z1",
    "Z3",
    "Z4",
  ],
  Buick: [
    "Velite",
    "6",
    "Cascada",
    "Century",
    "Enclave",
    "Encore",
    "GX",
    "Envision",
    "LaCrosse",
    "LE",
    "Sabre",
    "Regal",
    "TourX",
    "Rendezvous",
    "Roadmaster",
    "7",
    "Verano",
  ],
  BYD: [
    "Atto",
    "3",
    "Dolphin",
    "E2",
    "E5",
    "F0",
    "F3",
    "F6",
    "Flyer",
    "G3",
    "Han",
    "New",
    "Qin",
    "Plus",
    "S6",
    "Seagull",
    "Song",
    "Champion",
    "Tang",
    "Yuan",
  ],
  Cadillac: [
    "ATS",
    "BLS",
    "CT4",
    "CT5",
    "CT5-V",
    "CT6",
    "CTS",
    "DE",
    "Ville",
    "Eldorado",
    "ELR",
    "Escalade",
    "Fleetwood",
    "LYRIQ",
    "Seville",
    "SRX",
    "STS",
    "XT4",
    "XT5",
    "XT6",
    "XTS",
  ],
  Chevrolet: [
    "2500",
    "Astro",
    "Avalanche",
    "Aveo",
    "Beretta",
    "Blazer",
    "Bolt",
    "EUV",
    "EV",
    "C/K",
    "Series",
    "Camaro",
    "Caprice",
    "Captiva",
    "Cavalier",
    "City",
    "Express",
    "Cobalt",
    "Colorado",
    "Corvette",
    "Cruze",
    "Epica",
    "Equinox",
    "Evanda",
    "HHR",
    "Impala",
    "Kalos",
    "Lacetti",
    "Lanos",
    "Lumina",
    "APV",
    "Malibu",
    "Matiz",
    "Menlo",
    "Monte",
    "Carlo",
    "Monza",
    "Nexia",
    "Niva",
    "Nubira",
    "Orlando",
    "Prizm",
    "Rezzo",
    "S-10",
    "Silverado",
    "Sonic",
    "Spark",
    "SS",
    "SSR",
    "Suburban",
    "Tacuma",
    "Tahoe",
    "Tracker",
    "TrailBlazer",
    "Trans",
    "Sport",
    "Traverse",
    "Trax",
    "Uplander",
    "Venture",
    "Volt",
  ],
  Citroën: [
    "Acadiane",
    "AMI",
    "AX",
    "Axel",
    "Berlingo",
    "BX",
    "C-Crosser",
    "C-Elysee",
    "C-Zero",
    "C1",
    "C15",
    "C2",
    "C25",
    "C3",
    "Aircross",
    "Picasso",
    "Pluriel",
    "C4",
    "Cactus",
    "SpaceTourer",
    "X",
    "C5",
    "C6",
    "C8",
    "CX",
    "DS3",
    "DS4",
    "DS5",
    "e-Berlingo",
    "e-C4",
    "Evasion",
    "Grand",
    "Jumpy",
    "Nemo",
    "Saxo",
    "Space",
    "Tourer",
    "Xantia",
    "XM",
    "Xsara",
    "ZX",
  ],
  Chery: [
    "A1",
    "A13",
    "A15",
    "A5",
    "Amulet",
    "Beat",
    "CrossEastar",
    "E5",
    "Eastar",
    "Elara",
    "eQ",
    "eQ1",
    "eQ5",
    "Jaggi",
    "Karry",
    "Kimo",
    "M11",
    "QQ",
    "Tiggo",
    "2",
    "Pro",
    "3",
    "3x",
    "4",
    "5",
    "7",
    "8",
  ],
  Dacia: [
    "Dokker",
    "Stepway",
    "Duster",
    "Lodgy",
    "Logan",
    "MCV",
    "Nova",
    "Sandero",
    "StepWay",
    "Solenza",
    "Spring",
    "SuperNova",
  ],
  DAF: ["200", "600"],
  Daewoo: [
    "Damas",
    "Espero",
    "Gentra",
    "Kalos",
    "Lacetti",
    "Lanos",
    "Leganza",
    "Lublin",
    "Magnus",
    "Matiz",
    "Nexia",
    "Nubira",
    "Prince",
    "Sens",
    "Super",
    "Salon",
    "Tacuma",
    "Tico",
    "Racer",
  ],
  Dodge: [
    "600",
    "A-Series",
    "Avenger",
    "B-Models",
    "Caliber",
    "Caravan",
    "Challenger",
    "Charger",
    "Colt",
    "Dakota",
    "Dart",
    "Daytona",
    "Durango",
    "Grand",
    "Intrepid",
    "Journey",
    "M",
    "880",
    "Magnum",
    "Neon",
    "Nitro",
    "RAM",
    "1500",
    "2500",
    "3500",
    "Ram",
    "Van",
    "Stealth",
    "Stratus",
    "Viper",
  ],
  DS: ["3", "3 Crossback", "4", "4 Crossback", "7", "7 Crossback", "9"],
  Ferrari: [
    "488 GTB",
    "488 Spider",
    "California",
    "F12",
    "F8 Spider",
    "FF",
    "SF90",
  ],
  Fiat: [
    "1100",
    "124",
    "125",
    "126",
    "127",
    "128",
    "133",
    "1800",
    "1900",
    "242",
    "500",
    "500C",
    "500e",
    "500L",
    "500X",
    "Albea",
    "Argenta",
    "Barchetta",
    "Brava",
    "Bravo",
    "Cinquecento",
    "Coupe",
    "Croma",
    "Doblo",
    "Panorama",
    "E-Doblo",
    "Fiorino",
    "Freemont",
    "Fullback",
    "Grande",
    "Punto",
    "Idea",
    "Linea",
    "Marea",
    "Multipla",
    "Palio",
    "Panda",
    "Penny",
    "Qubo",
    "Regata",
    "(138)",
    "Regatta",
    "Ritmo",
    "Scudo",
    "Sedici",
    "Seicento",
    "Siena",
    "Stilo",
    "Talento",
    "Tempra",
    "Tipo",
    "Cross",
    "Ulysse",
    "Uno",
  ],
  Ford: [
    "B-Max",
    "Bronco",
    "II",
    "Sport",
    "C-Max",
    "Capri",
    "Contour",
    "Cougar",
    "Courier",
    "Crown",
    "Victoria",
    "E-250",
    "E-350",
    "E-series",
    "Econoline",
    "Econovan",
    "EcoSport",
    "Edge",
    "Escape",
    "Escort",
    "Excursion",
    "Expedition",
    "Explorer",
    "Trac",
    "F-150",
    "F-250",
    "F-350",
    "F-450",
    "F-650",
    "Fairlane",
    "Festiva",
    "Fiesta",
    "Active",
    "Flex",
    "Focus",
    "Fusion",
    "Galaxy",
    "Granada",
    "Grand",
    "KA",
    "Kuga",
    "LTD",
    "Maverick",
    "Mercury",
    "Mondeo",
    "Mustang",
    "Mach-E",
    "Orion",
    "Probe",
    "Puma",
    "Ranger",
    "S-Max",
    "Scorpio",
    "Sierra",
    "StreetKa",
    "Taunus",
    "Taurus",
    "Tempo",
    "Territory",
    "Tourneo",
    "Connect",
    "Custom",
    "Transit",
    "V8",
    "Windstar",
  ],
  GMC: [
    "Acadia",
    "USA",
    "Canyon",
    "Hummer",
    "EV",
    "SUV",
    "Savana",
    "Sierra",
    "Terrain",
    "Yukon",
  ],
  Honda: [
    "Accord",
    "Aerodeck",
    "Ascot",
    "Civic",
    "Shuttle",
    "Clarity",
    "Concerto",
    "CR-V",
    "CR-X",
    "del",
    "Sol",
    "CR-Z",
    "Crosstour",
    "e",
    "Element",
    "Elysion",
    "eNP1",
    "eNS1",
    "Everus",
    "VE-1",
    "Fit",
    "FR-V",
    "HR-V",
    "Insight",
    "Integra",
    "Jazz",
    "Crosstar",
    "Legend",
    "M-NV",
    "Odyssey",
    "Passport",
    "Pilot",
    "Prelude",
    "Quintet",
    "Ridgeline",
    "S2000",
    "Stream",
    "Vigor",
    "X-NV",
    "ZR-V",
  ],
  Hyundai: [
    "Accent",
    "Amica",
    "Atos",
    "Prime",
    "Avante",
    "Azera",
    "Coupe",
    "Creta",
    "Elantra",
    "GT",
    "Encino",
    "EV",
    "Eon",
    "Equus",
    "Galloper",
    "Genesis",
    "Getz",
    "Grand",
    "Santa",
    "Fe",
    "Starex",
    "Grandeur",
    "H",
    "100",
    "200",
    "300",
    "H-1",
    "H-Series",
    "i10",
    "i20",
    "i30",
    "Wagon",
    "i40",
    "Ioniq",
    "5",
    "6",
    "Electric",
    "ix20",
    "ix35",
    "ix55",
    "Kona",
    "Lafesta",
    "Lantra",
    "Marcia",
    "Matrix",
    "Maxcruz",
    "Palisade",
    "Pony",
    "S-Coupe",
    "FE",
    "Solaris",
    "Sonata",
    "Staria",
    "Terracan",
    "Tiburon",
    "Trajet",
    "Tucson",
    "Veloster",
    "Venue",
    "Veracruz",
    "XG",
  ],
  Infinity: [
    "EX",
    "25",
    "30",
    "35",
    "37",
    "FX",
    "45",
    "50",
    "G",
    "G25",
    "G35",
    "G37",
    "I30",
    "I35",
    "JX35",
    "M",
    "M25",
    "M30",
    "M35",
    "M37",
    "M45",
    "Q30",
    "Q40",
    "Q45",
    "Q50",
    "Q60",
    "Q70",
    "QX",
    "QX30",
    "QX4",
    "QX50",
    "QX55",
    "QX56",
    "QX60",
    "QX70",
    "QX80",
  ],
  Isuzu: [
    "Campo",
    "D-Max",
    "Gemini",
    "Midi",
    "Pick",
    "Up",
    "Rodeo",
    "Trooper",
    "VehiCross",
  ],
  Jaguar: [
    "E-Pace",
    "F-Pace",
    "F-Type",
    "I-Pace",
    "S-Type",
    "X-Type",
    "XE",
    "XF",
    "XJ",
    "XJS",
    "XK",
  ],
  Jeep: [
    "Cherokee",
    "Comanche",
    "Commander",
    "Compass",
    "Gladiator",
    "Grand",
    "Liberty",
    "Patriot",
    "Renegade",
    "Wagoneer",
    "Wrangler",
  ],
  Kia: [
    "Avella",
    "Besta",
    "Bongo",
    "Cadenza",
    "Carens",
    "Carnival",
    "Carstar",
    "Ceed",
    "Cerato",
    "Koup",
    "Clarus",
    "EV5",
    "EV6",
    "Forte",
    "Joice",
    "K3",
    "K5",
    "K7",
    "Magentis",
    "Mohave",
    "Morning",
    "Niro",
    "Opirus",
    "Optima",
    "Picanto",
    "Pregio",
    "Pride",
    "ProCeed",
    "Ray",
    "Retona",
    "Rio",
    "X",
    "X-Line",
    "Rondo",
    "Sedona",
    "Seltos",
    "Sephia",
    "Shuma",
    "Sorento",
    "Soul",
    "EV",
    "Spectra",
    "Sportage",
    "Stinger",
    "Stonic",
    "Telluride",
    "Venga",
    "XCeed",
  ],
  Lamborghini: ["Aventador", "Huracan", "Urus"],
  "Land Rover": [
    "Defender",
    "Discovery",
    "Discovery Sport",
    "Freelander",
    "Range Rover",
    "Range Rover Evoque",
    "Range Rover Sport",
    "Range Rover Velar",
  ],
  Lexus: [
    "CT",
    "ES",
    "GS",
    "GX",
    "HS",
    "IS",
    "LC",
    "LM",
    "LS",
    "LX",
    "NX",
    "RC",
    "RX",
    "RZ",
    "SC",
    "TX",
    "UX",
  ],
  Maserati: [
    "Ghibli",
    "GranTurismo",
    "Grecale",
    "Levante",
    "MC20",
    "Quattroporte",
  ],
  Mazda: [
    "121",
    "2",
    "3",
    "323",
    "5",
    "6",
    "626",
    "929",
    "Atenza",
    "B-series",
    "Biante",
    "BT-50",
    "Capella",
    "Cronos",
    "CX-3",
    "CX-30",
    "EV",
    "CX-5",
    "CX-50",
    "CX-60",
    "CX-7",
    "CX-9",
    "CX-90",
    "Demio",
    "E-series",
    "Efini",
    "MS-6",
    "Familia",
    "Millenia",
    "MPV",
    "MX-3",
    "MX-30",
    "MX-5",
    "MX-6",
    "Premacy",
    "Protege",
    "RX-7",
    "RX-8",
    "Tribute",
    "Xedos",
    "Xedos 6",
    "Xedos 9",
  ],
  "Mercedes-Benz": [
    "190",
    "230",
    "Pullman",
    "500K",
    "540K",
    "A-Class",
    "AMG-GT",
    "AMG-GT-4-Door",
    "Coupe",
    "B-Class",
    "C-Class",
    "Citan",
    "CL-Class",
    "CLA-Class",
    "CLC-Class",
    "CLK-Class",
    "CLS-Class",
    "E-Class",
    "EQA",
    "EQB",
    "EQC",
    "EQE",
    "SUV",
    "EQS",
    "EQV",
    "eVito",
    "G-Class",
    "GL-Class",
    "GLA-Class",
    "GLB-Class",
    "GLC-Class",
    "GLC-Class-Coupe",
    "GLE-Class",
    "GLE-Class-Coupe",
    "GLK-Class",
    "GLS-Class",
    "M-Class",
    "Maybach",
    "MB-Class",
    "Metris",
    "R-Class",
    "S-Class",
    "SL-Class",
    "SLC-Class",
    "SLK-Class",
    "T-Class",
    "V-Class",
    "Vaneo",
    "Viano",
    "Vito",
    "X-Class",
  ],
  MINI: [
    "Clubman",
    "Convertible",
    "Countryman",
    "Coupe",
    "Electric",
    "Hatch",
    "Paceman",
    "Roadster",
  ],
  Mitsubishi: [
    "3000-GT",
    "Airtrek",
    "ASX",
    "Carisma",
    "Chariot",
    "Colt",
    "Delica",
    "Diamante",
    "Dingo",
    "Dion",
    "Eclipse",
    "Eclipse-Cross",
    "Galant",
    "Grandis",
    "i-MiEV",
    "L-200",
    "L-300",
    "L-400",
    "Lancer",
    "Lancer-Evolution",
    "Mirage",
    "Montero",
    "Montero-Sport",
    "Nativa",
    "Outlander",
    "Outlander-Sport",
    "Outlander-XL",
    "Pajero",
    "Pajero-Mini",
    "Pajero-Pinin",
    "Pajero-Sport",
    "Pajero-Wagon",
    "Proton",
    "Raider",
    "RVR",
    "Santamo",
    "Sapporo",
    "Sigma",
    "Space-Runner",
    "Space-Star",
    "Space-Wagon",
    "Starion",
    "Virage",
  ],
  Nissan: [
    "100NX",
    "200SX",
    "350Z",
    "370Z",
    "Almera",
    "Classic",
    "Tino",
    "Altima",
    "Ariya",
    "Armada",
    "Axxess",
    "Bluebird",
    "Bluebird-Sylphy",
    "Cedric",
    "Cefiro",
    "Cherry",
    "Cima",
    "Clipper",
    "Cube",
    "e-NV200",
    "Elgrand",
    "Frontier",
    "Gloria",
    "GT-R",
    "Juke",
    "Kicks",
    "King",
    "Cab",
    "Kubistar",
    "Langley",
    "Largo",
    "Latio",
    "Laurel",
    "Leaf",
    "Leopard",
    "Liberta-Villa",
    "Liberty",
    "March",
    "Maxima",
    "Micra",
    "Murano",
    "Navara",
    "Note",
    "NP300",
    "NV",
    "NV1500",
    "NV200",
    "NV250",
    "NV300",
    "Paladin",
    "Pathfinder",
    "Patrol",
    "Pixo",
    "Prairie",
    "Presea",
    "Primastar",
    "Primera",
    "Pulsar",
    "Qashqai",
    "e-Power",
    "Qashqai+2",
    "Quest",
    "Rogue",
    "Rogue-Sport",
    "Safari",
    "Sentra",
    "Serena",
    "Silvia",
    "Skyline",
    "Stanza",
    "Sunny",
    "Sylphy",
    "Teana",
    "Terrano",
    "Terrano-II",
    "TIIDA",
    "Titan",
    "Trade",
    "Vanette",
    "Versa",
    "Versa-Note",
    "Wingroad",
    "X-Trail",
    "Xterra",
  ],
  Opel: [
    "1900",
    "Adam",
    "Admiral",
    "Agila",
    "Ampera",
    "Ampera-e",
    "Antara",
    "Arena",
    "Ascona",
    "Astra",
    "Astra-GTC",
    "Calibra",
    "Campo",
    "Combo",
    "Combo-Cargo",
    "Combo-Life",
    "Commodore",
    "Corsa",
    "Corsa-e",
    "Crossland",
    "Crossland-X",
    "Frontera",
    "Grandland",
    "Grandland-X",
    "Insignia",
    "Insignia-Country-Tourer",
    "Kadett",
    "Kapitan",
    "Karl",
    "Manta",
    "Meriva",
    "Mokka",
    "Mokka-e",
    "Monterey",
    "Omega",
    "Rekord",
    "Senator",
    "Signum",
    "Sintra",
    "Super-6",
    "Tigra",
    "Vectra",
    "Vivaro",
    "Vivaro-e",
    "Zafira",
    "Zafira-Life",
    "Zafira-Tourer",
  ],
  Peugeot: [
    "1007",
    "106",
    "107",
    "108",
    "2008",
    "205",
    "206",
    "207",
    "208",
    "3008",
    "301",
    "305",
    "306",
    "307",
    "308",
    "309",
    "4007",
    "4008",
    "405",
    "406",
    "407",
    "408",
    "5008",
    "508",
    "508-RXH",
    "605",
    "607",
    "806",
    "807",
    "Bipper",
    "e-2008",
    "e-208",
    "e-Rifter",
    "Expert",
    "iOn",
    "Landtrek",
    "Partner",
    "Ranch",
    "RCZ",
    "Rifter",
    "Traveller",
  ],
  Porsche: [
    "718-Boxster",
    "911",
    "918-Spyder",
    "924",
    "Boxster",
    "Cayenne",
    "Cayenne-Coupe",
    "Cayman",
    "Macan",
    "Panamera",
    "Taycan",
    "Taycan-Cross-Turismo",
    "Taycan-Sport-Turismo",
  ],
  Renault: [
    "11",
    "18",
    "19",
    "21",
    "25",
    "5",
    "9",
    "Arkana",
    "Captur",
    "City-K-ZE",
    "Clio",
    "Clio-Symbol",
    "Dokker",
    "Duster",
    "Espace",
    "Express",
    "Fluence",
    "Fluence-Z.E.",
    "Grand-Espace",
    "Grand-Kangoo",
    "Grand-Modus",
    "Grand-Scenic",
    "Kadjar",
    "Kangoo",
    "Kaptur",
    "Koleos",
    "Laguna",
    "Latitude",
    "Lodgy",
    "Logan",
    "Logan-MCV",
    "Logan-Stepway",
    "Megane",
    "Megane-E-Tech-Electric",
    "Megane-Scenic",
    "Modus",
    "Rapid",
    "Safrane",
    "Sandero",
    "Sandero-StepWay",
    "Scenic",
    "Scenic-RX4",
    "Scenic-XMOD",
    "Symbol",
    "Talisman",
    "Thalia",
    "Trafic",
    "Twingo",
    "Twizy-Z.E.",
    "Vel-Satis",
    "Wind",
    "Zoe",
  ],
  "Rolls-Royce": [
    "Cullinan",
    "Dawn",
    "Ghost",
    "Phantom",
    "Silver-Dawn",
    "Spectre",
    "Wraith",
  ],
  SEAT: [
    "Alhambra",
    "Altea",
    "Altea-Freetrack",
    "Altea-XL",
    "Arona",
    "Arosa",
    "Ateca",
    "Cordoba",
    "el-Born",
    "Exeo",
    "Exeo-ST",
    "Ibiza",
    "Ibiza-ST",
    "Inca",
    "Leon",
    "Leon-Sportstourer",
    "Malaga",
    "Marbella",
    "Mii",
    "Tarraco",
    "Terra",
    "Toledo",
  ],
  Škoda: [
    "105",
    "110",
    "130",
    "Citigo",
    "Enyaq-iV",
    "Fabia",
    "Fabia-Scout",
    "Favorit",
    "Felicia",
    "Forman",
    "Kamiq",
    "Karoq",
    "Kodiaq",
    "Kodiaq-Scout",
    "Octavia",
    "Octavia-Scout",
    "Rapid",
    "Roomster",
    "Roomster-Scout",
    "Scala",
    "Spaceback",
    "Superb",
    "Superb-Scout",
    "Yeti",
  ],
  Subaru: [
    "Ascent",
    "B9-Tribeca",
    "BRZ",
    "Crosstrek",
    "Forester",
    "Impreza",
    "Impreza-Outback",
    "Impreza-WRX",
    "Impreza-WRX-STI",
    "Justy",
    "Legacy",
    "Legacy-Outback",
    "Leone",
    "Levorg",
    "Libero",
    "Outback",
    "Solterra",
    "Trezia",
    "Tribeca",
    "WRX",
    "WRX-STI",
    "XV",
  ],
  Suzuki: [
    "Aerio",
    "Alto",
    "Baleno",
    "Carry",
    "Forenza",
    "Grand-Vitara",
    "Grand-Vitara-XL7",
    "Ignis",
    "Jimny",
    "Kizashi",
    "Liana",
    "MR-Wagon",
    "S-Cross",
    "Samurai",
    "SJ",
    "Splash",
    "Swift",
    "SX4",
    "Vitara",
    "Wagon-R",
    "X-90",
    "XL7",
  ],
  Smart: ["EQ-Forfour", "EQ-Fortwo", "Forfour", "Fortwo", "Roadster"],
  Tesla: ["Model 3", "Model S", "Model X", "Model Y", "Roadster"],
  Toyota: [
    "4Runner",
    "Alphard",
    "Altezza",
    "Aqua",
    "Aurion",
    "Auris",
    "Avalon",
    "Avensis",
    "Avensis-Verso",
    "Aygo",
    "bB",
    "bZ3",
    "bZ4X",
    "C-HR",
    "C-HR-EV",
    "Caldina",
    "Cami",
    "Camry",
    "Camry-Solara",
    "Carina",
    "Carina-E",
    "Carina-ED",
    "Cavalier",
    "Celica",
    "Chaser",
    "Corolla",
    "Corolla-Cross",
    "Corolla-iM",
    "Corolla-Verso",
    "Corona",
    "Corona-Exiv",
    "Corsa",
    "Cressida",
    "Cresta",
    "Crown",
    "Echo",
    "Estima",
    "FJ",
    "Cruiser",
    "Fortuner",
    "GR",
    "Supra",
    "Grand-Hiace",
    "Granvia",
    "GT-86",
    "Harrier",
    "Hiace",
    "Highlander",
    "Hilux",
    "Ipsum",
    "IQ",
    "Izoa",
    "Land-Cruiser",
    "Land-Cruiser-Prado",
    "LiteAce",
    "Mark-II",
    "Matrix",
    "Mirai",
    "Model-F",
    "MR2",
    "Paseo",
    "Picnic",
    "Previa",
    "Prius",
    "Prius-C",
    "Prius-MPV",
    "Prius-Prime",
    "Proace",
    "Proace-City",
    "Proace-City-Verso",
    "Proace-Verso",
    "RAV4",
    "RAV4-EV",
    "RAV4-PHEV",
    "Sequoia",
    "Sienna",
    "Soarer",
    "Sprinter",
    "Sprinter-Marino",
    "Starlet",
    "T100",
    "Tacoma",
    "Tercel",
    "Town",
    "Ace",
    "Tundra",
    "Urban-Cruiser",
    "Vellfire",
    "Venza",
    "Verso",
    "Vista",
    "Vitz",
    "Voxy",
    "Windom",
    "Yaris",
    "Yaris-Cross",
    "Yaris-Verso",
  ],
  Volkswagen: [
    "1500",
    "Amarok",
    "Arteon",
    "Arteon-Shooting-Brake",
    "Atlas",
    "Atlas-Cross-Sport",
    "Beetle",
    "Bora",
    "Caddy",
    "Caddy-Alltrack",
    "California",
    "Caravelle",
    "CC",
    "Passat",
    "Cross-Golf",
    "Cross-Golf-Plus",
    "Cross-Polo",
    "Cross-Touran",
    "e-Bora",
    "e-Golf",
    "e-Lavida",
    "E-Tharu",
    "Eos",
    "Eurovan",
    "Fox",
    "Gol",
    "Golf",
    "Golf-Alltrack",
    "Golf-GTD",
    "Golf-GTE",
    "Golf-GTI",
    "Golf-Plus",
    "Golf-R",
    "Golf-Sportsvan",
    "I.D.",
    "Buzz",
    "ID.3",
    "ID.4",
    "ID.4-Crozz",
    "X",
    "ID.5",
    "ID.6",
    "ID.6-Crozz",
    "Jetta",
    "Kafer",
    "Lupo",
    "Multivan",
    "Passat-Alltrack",
    "Phaeton",
    "Pointer",
    "Polo",
    "Routan",
    "Santana",
    "Scirocco",
    "Sharan",
    "T-Cross",
    "T-Roc",
    "Taigun",
    "Taos",
    "Taro",
    "Teramont",
    "Tiguan",
    "Tiguan-Allspace",
    "Touareg",
    "Touareg-R",
    "Touran",
    "Transporter",
    "Typ-4",
    "Up",
    "Vento",
  ],
  Volvo: [
    "144",
    "240",
    "242",
    "244",
    "340",
    "343",
    "345",
    "360",
    "440",
    "460",
    "480",
    "740",
    "760",
    "850",
    "940",
    "960",
    "C30",
    "C40-Recharge",
    "C70",
    "EX30",
    "EX90",
    "S40",
    "S60",
    "S60-Cross-Country",
    "S70",
    "S80",
    "S90",
    "V40",
    "V40-Cross-Country",
    "V50",
    "V60",
    "V60-Cross-Country",
    "V70",
    "V90",
    "V90-Cross-Country",
    "XC40",
    "XC40-Recharge",
    "XC60",
    "XC60-Recharge",
    "XC70",
    "XC90",
  ],

  // Ford: ['F-150', 'Mustang', 'Escape', 'Explorer', 'Focus'],
  // Chevrolet: ['Silverado', 'Malibu', 'Equinox', 'Camaro', 'Traverse'],
  // Honda: ['Accord', 'Civic', 'CR-V', 'Pilot', 'Odyssey'],
  // Nissan: ['Altima', 'Maxima', 'Rogue', 'Pathfinder', 'Titan'],
  // Volkswagen: ['Jetta', 'Passat', 'Tiguan', 'Atlas', 'Golf'],
  // 'Mercedes-Benz': ['C-Class', 'E-Class', 'GLC', 'GLE', 'S-Class'],
  // BMW: ['3 Series', '5 Series', 'X3', 'X5', '7 Series'],
  // Audi: ['A3', 'A4', 'Q5', 'Q7', 'A6'],
  // Hyundai: ['Elantra', 'Tucson', 'Santa Fe', 'Kona', 'Palisade'],
  // Kia: ['Optima', 'Sorento', 'Telluride', 'Sportage', 'Soul'],
  // Subaru: ['Outback', 'Forester', 'Impreza', 'Crosstrek', 'Legacy'],
  // Tesla: ['Model S', 'Model 3', 'Model X', 'Model Y', 'Cybertruck'],
  // Lexus: ['ES', 'RX', 'NX', 'GX', 'IS'],
  // Mazda: ['Mazda3', 'Mazda6', 'CX-5', 'CX-9', 'MX-5'],
  // Jeep: ['Wrangler', 'Cherokee', 'Grand Cherokee', 'Renegade', 'Compass'],
  // Volvo: ['S60', 'XC40', 'XC60', 'XC90', 'V60'],
  // Ferrari: ['488 GTB', '812 Superfast', 'Portofino', 'F8 Tributo', 'Roma'],
  // Porsche: ['911', 'Cayenne', 'Panamera', 'Macan', 'Taycan'],
  // Jaguar: ['XE', 'XF', 'F-PACE', 'E-PACE', 'I-PACE'],
};

const years = ["2017", "2018", "2019", "2020", "2021", "2022", "2023", "2024"];

const korobka = ["Механіка", "Автомат", "Робот"];

const kuzova = [
  "Седан",
  "Універсал",
  "Хетчбек",
  "Фастбек",
  "Родстер",
  "Пікап",
  "Позашляховик / Кросовер",
  "Мінівен",
  "Мікровен",
  "Кабріолет",
  "Купе",
  "Лімузин",
  "Ліфтбек",
];

const fuels = [
  "Бензин",
  "Дизель",
  "Газ",
  "Газ метан / Бензин",
  "Газ пропан-бутан / Бензин",
  "Гібрид(НЕV)",
  "Гібрид(PНЕV)",
  "Електро",
];

const engineCapacity = [
  "5.7",
  "5.5",
  "5",
  "4.5",
  "4",
  "3.5",
  "3.3",
  "3.2",
  "2.7",
  "2.5",
  "2.4",
  "2",
  "1.9",
  "1.8",
  "1.6",
  "1.5",
  "1.4",
  "1.3",
  "1.2",
  "1.1",
  "1",
];

const ecologicalStandard = [
  "Eвро-1",
  "Евро-2",
  "Евро-3",
  "Евро-4",
  "Евро-5",
  "Евро-6",
];

const engineDrive = ["Передній", "Задній", "Повний"];

const numberOfDoors = ["1", "2", "3", "4", "5", "6"];
const rangeOfYears = ["1", "2", "3", "4", "5", "6"];

const colors = [
  "Бежевий",
  "Чорний",
  "Білий",
  "Синій",
  "Коричневий",
  "Сірий",
  "Зелений",
  "Червоний",
];

const DTP = ["Так, був у ДТП", "Ні, не був у ДТП"];

const SUB = ["Так, отримувати сповіщення", "Ні, не отримувати сповіщення"];
const technicalCondition = [
  "Повністю непошкоджене",
  "Професійно відремонтавані пошкодження",
  "Невідремонтовані пошкодження",
  "Не на ходу / На запчастини",
];

export {
  SUB,
  technicalCondition,
  DTP,
  colors,
  numberOfDoors,
  engineDrive,
  ecologicalStandard,
  engineCapacity,
  kuzova,
  korobka,
  countries,
  regionsByCountry,
  raionByRegions,
  cityOrStreetByRaion,
  ANIMAL,
  ROOMS,
  carModels,
  carBrands,
  years,
  fuels,
  rangeOfYears,
  cityOrStreetForRent,
};
