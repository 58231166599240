import React, { useState, useEffect, useCallback } from "react";
import { useForm } from "react-hook-form";
import Style from "./Form.module.css";
import { useTelegram } from "../../hooks/useTelegram";
import {
  cityOrStreetForRent,
  cityOrStreetByRaion,
  regionsByCountry,
  raionByRegions,
  countries,
  ANIMAL,
  ROOMS,
} from "../config";
import axios from "axios";
import Dropdown from "../Dropdown/Dropdown";
import { SlArrowDown, SlArrowUp } from "react-icons/sl";
import { showModal } from "../Modal/Modal";
import images from "../assets";
// import Modal from "../Modal/Modal";
const Form = () => {
  const { tg } = useTelegram();
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    reset,
  } = useForm();
  const [selectedOption, setSelectedOption] = useState("Select Option");
  const [selectedCountry, setSelectedCountry] = useState("Виберіть країну");
  const [selectedRegion, setSelectedRegion] = useState("Виберіть область");
  const [selectedRaion, setSelectedRaion] = useState("Виберіть район/місто");
  const [selectedCityOrRaion, setSelectedCityOrRaion] = useState([]);
  const [menu, setMenu] = useState(false);
  const [openRegion, setOpenRegion] = useState(false);
  const [openOption, setOpenOption] = useState(false);
  const [userName, setUserName] = useState("");
  const [openRaion, setOpenRaion] = useState(false);
  const [openCityOrRaion, setOpenCityOrRaion] = useState(false);
  const [isAnimal, setIsAnimal] = useState(false);
  const [isOwner, setIsOwner] = useState(false);
  const [selectedAnimal, setSelectedAnimal] = useState("Виберіть відповідь");
  const [openAnimalMenu, setOpenAnimalMenu] = useState(false);
  const [openTypeMenu, setOpenTypeMenu] = useState(false);
  const [openRoomMenu, setOpenRoomMenu] = useState(false);
  const [selectedRooms, setSelectedRooms] = useState([]);
  const [userDescription, setUserDescription] = useState("");
  const [userPrice, setUserPrice] = useState("");
  const [selectedOnwer, setSelectedOwner] = useState("Виберіть відповідь");
  const [ownerMenu, setOwnerMenu] = useState(false);
  const [selectedSubsc, setSelectedSubsc] = useState("Виберіть відповідь");
  const [subscription, setSubscription] = useState(false);
  const [subscriptionMenu, setSubscriptionMenu] = useState(false);
  const [isForBuy, setisForBuy] = useState(false);
  const [selectedType, setSelectedType] = useState("Виберіть відповідь");
  const [isLoading, setIsLoading] = useState(true);
  const findApart = true;
  const appUrl = process.env.REACT_APP_API_URL;

  // const [masterOptions, setMasterOptions] = useState({
  //   external: [],
  //   internal: [],
  //   complex: [],
  // });

  // const toggleOption = (prev, option) => {
  //   if (prev.includes(option)) {
  //     return prev.filter((item) => item !== option);
  //   } else {
  //     return [...prev, option];
  //   }
  // };

  // const toggleSubOption = (option) => {
  //   switch (masterOption) {
  //     case 'external':
  //       setSelectedSubOptionExternal((prev) => toggleOption(prev, option));
  //       break;
  //     case 'internal':
  //       setSelectedSubOptionInternal((prev) => toggleOption(prev, option));
  //       break;
  //     case 'complex':
  //       setSelectedSubOptionComplex((prev) => toggleOption(prev, option));
  //       break;
  //     default:
  //       break;
  //   }
  // };
  // const handleSubOption = () => {
  //   setListVisible(!isListVisible);
  // };

  // const handleMaster = () => {
  //   setSelectedWorkOption(true);
  // };

  // const handleMasterOption = (e) => {
  //   setMasterOption(e.target.getAttribute('data-value'));
  //   setSelectedWorkOption(false);
  //   setMaster(true);
  // };
  // const handleOption = () => {
  //   setOpenOption(!openOption);
  // };

  // const handleOptionList = (e) => {
  //   const selectedValue = e.target.getAttribute('data-value');
  //   setSelectedOption(e.target.getAttribute('data-value'));
  //   setOpenOption(false);
  //   setSelectedWorkOption(true);
  //   setValue('option', selectedValue);
  // };
  const openMenu = () => {
    setMenu(!menu);
  };

  const [dataUser, setDataUser] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  function getUrlParams() {
    const params = new URLSearchParams(window.location.search);
    const chatId = params.get("chatId");
    return chatId;
  }
  function getUrlTgname() {
    const params = new URLSearchParams(window.location.search);
    const tgname = params.get("tgname") || "N/A";
    return tgname;
  }

  async function sendChatIdToBackend(chatId) {
    try {
      // GET request to send chatId as a query parameter
      const response = await axios.post(`${appUrl}/api/logChatId`, {
        chatId: chatId,
      });
      console.log("response", response.data);

      setDataUser(response.data);
    } catch (error) {
      console.error("Error sending chat ID or receiving data:", error);
    }
  }

  useEffect(() => {
    const chatId = getUrlParams();
    const tgname = getUrlTgname();
    if (chatId && tgname !== "N/A") {
      //console.log("Sending chatId to backend:", chatId);
      //sendChatIdToBackend(chatId);
    } else {
      showModal(
        `Щоб почати пошук оголошень,  додайте ім'я користувача(username) в налаштування телеграм, мій аккаунт і введіть назву та перезавантажте бота /start по кнопці Menu .`
      );
      console.log("Chat ID not found!");
    }
  }, []);

  useEffect(() => {
    if (dataUser) {
      console.log("dataUser updated:", dataUser);
      //setUserDescription(dataUser);
      console.log(
        "paymentStatus",
        dataUser.paymentStatus,
        "counter",
        dataUser.counter
      );
      if (dataUser.paymentStatus == false || dataUser.counter === 1) {
        //showModal(`Оплата успішна`);
        //setIsModalOpen(true);
        showModal(
          `Ваш пробний період завершився. Щоб продовжити користуватися даним функіоналом , виберіть відповідний тариф у головному меню по кнопці '💼 Тарифний план' і здійсніть оплату.`
        );
      }
      if (
        dataUser.paymentStatus == true &&
        dataUser.counter === 4 &&
        dataUser.amount === 3
      ) {
        showModal(
          `Ваш тарифний план завершився. Щоб продовжити користуватися даним функіоналом , виберіть відповідний тариф у головному меню по кнопці '💼 Тарифний план' і здійсніть оплату.`
        );
      }
    }
  }, [dataUser]);

  // useEffect(() => {
  //   console.log("userDescription updated:", userDescription);
  // }, [userDescription]);
  // useEffect(() => {
  //   console.log("dataFromDb:", dataUser);
  // }, [dataUser]);
  // // Function to fetch user state from the backend
  // async function fetchUserState(chatId) {
  //   try {
  //     // Use GET request to fetch data
  //     const response = await axios.get(
  //       `https://dfc7-188-191-42-31.ngrok-free.app/api/getUserState?chatId=${chatId}`
  //     );

  //     // Response handling
  //     const userState = response.data;
  //     console.log(userState); // Axios automatically parses JSON response
  //     return userState;
  //   } catch (error) {
  //     console.error("Error fetching user state:", error);
  //     return null;
  //   }
  // }

  // // When the page is fully loaded
  // window.onload = async function () {
  //   const chatId = getUrlParams(); // Get the chatId from the URL
  //   if (chatId) {
  //     // Query the user state from the backend
  //     const userState = await fetchUserState(chatId);

  //     // Alert the user based on the state from the database
  //     if (userState) {
  //       alert(
  //         `Your chat ID is ${chatId}. Your trial ends in ${userState.trialEndsIn} days. First time user ${userState.trialEndsIn}`
  //       );
  //     } else {
  //       alert(`Your chat ID is ${chatId}. Could not retrieve user state.`);
  //     }
  //   } else {
  //     alert("Chat ID not found!");
  //   }
  //};
  // function getUrlParams() {
  //   const params = new URLSearchParams(window.location.search);
  //   const chatId = params.get("chatId");
  //   return chatId;
  // }

  // // Use the chatId in your logic
  // window.onload = function () {
  //   const chatId = getUrlParams(); // Get the chatId from the URL
  //   if (chatId) {
  //     alert(`Your chat ID is ${chatId}`); // Display the alert with the chatId
  //   } else {
  //     alert("Chat ID not found!"); // Handle case where chatId is not present
  //   }
  // };

  const openOnwerMenu = () => {
    setOwnerMenu(!ownerMenu);
  };

  const openSubcriptionMenu = () => {
    setSubscriptionMenu(!subscriptionMenu);
  };

  const handleMenu = (country) => {
    setSelectedCountry(country);
    setValue("country", country);
    setMenu(false); // Close the country dropdown first

    // Use a callback to ensure the region dropdown opens after the country dropdown is closed
    setTimeout(() => {
      setOpenRegion(true);
    }, 0); // Close the dropdown after selecting an option
  };

  const handleRegionMenu = (region) => {
    setSelectedRegion(region);
    setValue("region", region);
    setOpenRegion(false);
    setOpenRaion(true);
  };

  const handleRaionMenu = (raion) => {
    setSelectedRaion(raion);
    setValue("raion", raion);
    setOpenRaion(false);
    setOpenCityOrRaion(true);
  };
  const calculateExtendedUntil = () => Math.floor(Date.now() / 1000) + 6 * 60;
  //console.log(calculateExtendedUntil());

  const handleCityOrRaion = (cityOrRaion) => {
    setSelectedCityOrRaion((prevState) => {
      const newItems = prevState.includes(cityOrRaion)
        ? prevState.filter((item) => item !== cityOrRaion)
        : [...prevState, cityOrRaion];
      return newItems;
    });
    //setOpenCityOrRaion(false);
    //setSelectedCityOrRaion(cityOrRaion);
    //setValue('cityOrRaion');
    //setOpenCityOrRaion(false);
    //setOpenRoomMenu(true);
    setOpenTypeMenu(true);
  };

  const handleAnimalMenu = (props) => {
    setSelectedAnimal(props);
    setIsAnimal(props === "Yes");
    setOpenAnimalMenu(false);
    setOwnerMenu(true);
  };

  const handleisForBuyMenu = (props) => {
    setSelectedType(props);
    setisForBuy(props === "Yes");
    setOpenTypeMenu(false);
    //setOpenAnimalMenu(true);
    setOpenRoomMenu(true);
  };

  const handleOnwerMenu = (props) => {
    setSelectedOwner(props);
    setIsOwner(props === "Yes");
    setOwnerMenu(false);
    setSubscriptionMenu(true);
  };

  const handleSubscribeMenu = (props) => {
    setSelectedSubsc(props);
    setSubscription(props === "Yes");
    setSubscriptionMenu(false);
  };

  const handleRoomMenu = (room) => {
    setSelectedRooms((prevState) => {
      const newItems = prevState.includes(room)
        ? prevState.filter((item) => item !== room)
        : [...prevState, room];
      return newItems;
    });
    //setOpenTypeMenu(true);
    setOpenAnimalMenu(true);
  };

  const handleNameChange = (e) => {
    const value = e.target.value;
    setUserName(value);
    localStorage.setItem("userName", value);
  };

  const onSendData = useCallback(async () => {
    const data = {
      selectedCountry,
      selectedRegion,
      selectedRaion,
      userName,
      userPrice,
      selectedCityOrRaion,
      selectedRooms,
      isAnimal,
      isOwner,
      findApart,
      subscription,
      isForBuy,
    };

    try {
      console.log("Sending data:", data); // Debugging line
      tg.sendData(JSON.stringify(data));
      console.log("Data sent successfully");
      // axios.post("https://c186-188-191-42-31.ngrok-free.app/send-invoice", {
      //   chatId,
      // });
      //console.log("Invoice request sent successfully");
    } catch (error) {
      console.error("Error submitting form:", error);
      // Handle error if needed
    }
  }, [
    selectedCountry,
    selectedRegion,
    selectedRaion,
    userName,
    userPrice,
    selectedCityOrRaion,
    selectedRooms,
    isAnimal,
    isOwner,
    findApart,
    subscription,
    isForBuy,
  ]);

  useEffect(() => {
    tg.onEvent("mainButtonClicked", onSendData);
    return () => {
      tg.offEvent("mainButtonClicked", onSendData);
    };
  }, [onSendData]);

  useEffect(() => {
    tg.MainButton.setParams({
      text: "Send data",
    });
  }, []);

  useEffect(() => {
    if (
      selectedCountry !== "Виберіть країну" &&
      selectedRegion !== "Виберіть область" &&
      selectedCityOrRaion !== "Виберіть місто/район" &&
      selectedRooms !== "Виберіть кількість кімнат" &&
      selectedRaion !== "Виберіть район/місто" &&
      (selectedAnimal !== "Виберіть відповідь" || isForBuy == true) &&
      userName.length >= 3 &&
      userPrice !== "" &&
      selectedSubsc !== "Виберіть відповідь" &&
      selectedType !== "Виберіть відповідь"
    ) {
      tg.MainButton.show();
    } else {
      tg.MainButton.hide();
    }
  }, [
    selectedCountry,
    selectedRegion,
    selectedRaion,
    selectedCityOrRaion,
    selectedAnimal,
    selectedRooms,
    userName,
    userPrice,
    selectedSubsc,
    selectedType,
  ]);
  // useEffect(() => {
  //   console.log("typeof price", typeof userPrice);
  // }, [userPrice]);

  useEffect(() => {
    if (selectedRaion) {
      setSelectedCityOrRaion([]);
    }
  }, [selectedRaion]);

  useEffect(() => {
    const storedName = localStorage.getItem("userName");

    if (storedName) {
      setUserName(storedName);
    }
  }, []);
  useEffect(() => {
    // Simulate a delay for loading
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 2000); // Replace with actual data fetching or API call logic

    return () => clearTimeout(timer); // Cleanup the timeout
  }, []);

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "100vh",
          //backgroundColor: "#f0f0f0", // Optional: background color for better visibility
        }}
      >
        <img
          src={images.loader}
          alt="Loading..."
          style={{ width: "100px", height: "100px" }} // Adjust size as needed
        />
        {/* <h5 className="marquee-text" style={{ textAlign: "center" }}>
          ��������� ���� ������� �������������
          <p>Please wait while the page is loading</p>
        </h5> */}
      </div>
    );
  }

  return (
    <div className={Style.Forma}>
      <form>
        {/* <h1>
          Знайти квартиру/дім | Find an apartment <>🏘</>
        </h1> */}
        <div className="marquee-container">
          <h1 className="marquee-text">
            👀Знайти об'єкт нерухомості | Find an apartment 🏡
          </h1>
        </div>
        <label>
          <h3>👤 Ваше ім'я | Your name</h3>
          <input
            type="text"
            value={userName}
            onChange={handleNameChange}
            style={{ color: "black", fontWeight: "bold" }}
          />
        </label>
        {errors.name && (
          <div style={{ height: 40 }}>
            <p>{errors.name.message}</p>
          </div>
        )}

        <Dropdown
          label="🌍 Країна | Country"
          value={selectedCountry}
          items={menu ? countries : null}
          onToggle={openMenu}
          onSelect={handleMenu}
          isOpen={menu}
        />

        <Dropdown
          label="🌆 Область | Region"
          value={selectedRegion}
          items={
            openRegion && selectedCountry !== "Виберіть країну"
              ? regionsByCountry[selectedCountry]
              : null
          }
          onToggle={() => setOpenRegion(!openRegion)}
          onSelect={handleRegionMenu}
          isOpen={openRegion}
        />

        <Dropdown
          label="🏙️ Район/Місто | District/City"
          value={selectedRaion}
          items={
            openRaion && selectedRegion !== "Виберіть область"
              ? raionByRegions[selectedRegion]
              : null
          }
          onToggle={() => setOpenRaion(!openRaion)}
          onSelect={handleRaionMenu}
          isOpen={openRaion}
        />
        <label>
          <h3>🏠 Місто/Райони | City/District</h3>
          <div
            className="custom-dropdown"
            {...register("cityOrStrt", {
              required: "The city is mandatory",
            })}
          >
            <div
              className="dropdown-button"
              onClick={() => setOpenCityOrRaion(!openCityOrRaion)}
            >
              <h4>
                {" "}
                {selectedCityOrRaion.length > 0
                  ? selectedCityOrRaion.join(", ")
                  : "Виберіть місто/райони"}
                {openCityOrRaion ? (
                  <SlArrowUp
                    style={{
                      float: "right",
                      color: "black",
                      marginTop: "10px",
                    }}
                  />
                ) : (
                  <SlArrowDown
                    style={{
                      float: "right",
                      color: "black",
                      marginTop: "10px",
                    }}
                  />
                )}
              </h4>
            </div>
            {openCityOrRaion && selectedRaion !== "Виберіть район/місто" && (
              <div className="dropdown-list">
                {cityOrStreetForRent[selectedRaion].map((city, index) => (
                  <div
                    key={index}
                    className="dropdown-item"
                    onClick={() => handleCityOrRaion(city)}
                  >
                    <h4>{city}</h4>
                  </div>
                ))}
              </div>
            )}
          </div>
        </label>
        <Dropdown
          label="🔑 Шукаєте для купівлі? | Looking to buy?"
          value={selectedType}
          items={
            openTypeMenu && selectedRaion !== "Виберіть місто/район"
              ? ANIMAL
              : null
          }
          onToggle={() => setOpenTypeMenu(!openTypeMenu)}
          onSelect={handleisForBuyMenu}
          isOpen={openTypeMenu}
        />

        <label>
          <h3>🛏️ Кількість кімнат | Number of rooms</h3>
          <div className="custom-dropdown">
            <div
              className="dropdown-button"
              onClick={() => setOpenRoomMenu(!openRoomMenu)}
            >
              <h4>
                {" "}
                {selectedRooms.length > 0
                  ? selectedRooms.join(", ")
                  : "Виберіть кількість кімнат"}
                {openRoomMenu ? (
                  <SlArrowUp
                    style={{
                      float: "right",
                      color: "black",
                      marginTop: "10px",
                    }}
                  />
                ) : (
                  <SlArrowDown
                    style={{
                      float: "right",
                      color: "black",
                      marginTop: "10px",
                    }}
                  />
                )}
              </h4>
            </div>
            {openRoomMenu && selectedRaion !== "Виберіть місто/район" && (
              <div className="dropdown-list">
                {ROOMS.map((room, index) => (
                  <div
                    key={index}
                    className="dropdown-item"
                    onClick={() => handleRoomMenu(room)}
                  >
                    <h4>{room}</h4>
                  </div>
                ))}
              </div>
            )}
          </div>
        </label>

        {selectedType !== "Yes" && (
          <Dropdown
            label="🐾 Ви з твариною | Are you with animal ?"
            value={selectedAnimal}
            items={
              openAnimalMenu && selectedRaion !== "Виберіть місто/район"
                ? ANIMAL
                : null
            }
            onToggle={() => setOpenAnimalMenu(!openAnimalMenu)}
            onSelect={handleAnimalMenu}
            isOpen={openAnimalMenu}
          />
        )}

        <Dropdown
          label="👤 Тільки від власника| Only from the owner ?"
          value={selectedOnwer}
          items={ownerMenu ? ANIMAL : null}
          onToggle={openOnwerMenu}
          onSelect={handleOnwerMenu}
          isOpen={ownerMenu}
        />
        <Dropdown
          label="✏️ Підписатися на схожі варіанти | Subscribe to similar options?"
          value={selectedSubsc}
          items={subscriptionMenu ? ANIMAL : null}
          onToggle={openSubcriptionMenu}
          onSelect={handleSubscribeMenu}
          isOpen={subscriptionMenu}
        />

        <label>
          <h3>
            💵Сума на яку Ви розраховуєте | The amount you are counting on
          </h3>
          <input
            type="number"
            placeholder="Введіть ціну у USD. | Enter the price in USD."
            onChange={(e) => setUserPrice(e.target.value)}
            style={{ color: "black", fontWeight: "bold" }}
          />
        </label>
        {/* {selectedType !== "Yes" && (
          <>
            <h3>📝 Опис: | Description:</h3>

            <textarea
              style={{
                height: "100px",
                width: "100%",
                // color: "gold",
                fontWeight: "bold",
                fontSize: "16px",
              }}
              placeholder="Введіть короткий опис скільки людей буде проживати та на який термін | Enter a brief description of how many people will be staying and for what period "
              onChange={(e) => setUserDescription(e.target.value)}
            />
          </>
        )} */}
      </form>
      <div style={{ height: "10px" }}></div>
    </div>
  );
};

export default Form;
