import { useForm } from "react-hook-form";
import { useState, useCallback, useEffect } from "react";
import { useTelegram } from "../../hooks/useTelegram";
import { showModal } from "../Modal/Modal";
import axios from "axios";
export default function Support() {
  const { tg } = useTelegram();
  const [sellerName, setSellerName] = useState("");
  const [userSubject, setUserSubject] = useState("");
  const [userDescription, setUserDescription] = useState("");
  const [dataUser, setDataUser] = useState(null);
  const support = true;
  const appUrl = process.env.REACT_APP_API_URL;

  function getUrlParams() {
    const params = new URLSearchParams(window.location.search);
    const chatId = params.get("chatId");
    return chatId;
  }
  function getUrlTgname() {
    const params = new URLSearchParams(window.location.search);
    const tgname = params.get("tgname") || "N/A";
    return tgname;
  }

  async function sendChatIdToBackend(chatId) {
    try {
      // GET request to send chatId as a query parameter
      const response = await axios.post(`${appUrl}/api/checkSupport`, {
        chatId: chatId,
      });
      //console.log("response", response.data);
      // Process the response data
      //const userState = response.data;
      setDataUser(response.data);
    } catch (error) {
      console.error("Error sending chat ID or receiving data:", error);
    }
  }

  useEffect(() => {
    const chatId = getUrlParams();
    const tgname = getUrlTgname();
    if (chatId && tgname !== "N/A") {
      //console.log("Sending chatId to backend:", chatId);
      sendChatIdToBackend(chatId);
    } else {
      showModal(
        `Щоб продовжити, додайте ім'я користувача(username) в налаштування телеграм, мій аккаунт і введіть назву, після того перезавантажнте бота командою "/start" по кнопці "Menu".`
      );
      //console.log("Chat ID not found!");
    }
  }, []);

  useEffect(() => {
    if (dataUser) {
      //console.log("DataUser", dataUser);
      if (dataUser === "Support form not found") {
      } else {
        showModal(
          `Будь ласка, зачекайте, поки оброблять ваш попередній запит. Дякуємо за розуміння.`
        );
      }
    }
  }, [dataUser]);

  const onSendData = useCallback(async () => {
    const data = {
      userSubject,
      userDescription,
      support,
    };

    try {
      //console.log("Sending data:", data); // Debugging line
      tg.sendData(JSON.stringify(data));
      //console.log("Data sent successfully");
      //axios.post('https://4577-188-191-42-31.ngrok-free.app/web-data', data);
      //console.log('Data sent successfully');
    } catch (error) {
      console.error("Error submitting form:", error);
      // Handle error if needed
    }
  }, [userSubject, userDescription]);

  useEffect(() => {
    tg.onEvent("mainButtonClicked", onSendData);
    return () => {
      tg.offEvent("mainButtonClicked", onSendData);
    };
  }, [onSendData]);

  useEffect(() => {
    tg.MainButton.setParams({
      text: "Send data",
    });
  }, []);

  useEffect(() => {
    if (userDescription !== "" && userSubject.length !== "") {
      tg.MainButton.show();
    } else {
      tg.MainButton.hide();
    }
  }, [userSubject, userDescription]);

  return (
    <form>
      <div className="marquee-container">
        <h1 className="marquee-text">
          ✏️Форма зворотнього зв'язку | Feedback form 💬
        </h1>
      </div>
      <label>
        <h3>Тема звернення | Subject</h3>
        <input
          type="text"
          placeholder="Введіть тему звернення |  Enter the subject of your inquiry "
          onChange={(e) => setUserSubject(e.target.value)}
          style={{ color: "black", fontWeight: "bold" }}
        />
      </label>

      <h3>Опис | Description</h3>

      <textarea
        style={{
          height: "300px",
          width: "100%",
          fontWeight: "bold",
          fontSize: "16px",
        }}
        placeholder="Введіть розгорнутий опис | Enter a detailed description. "
        onChange={(e) => setUserDescription(e.target.value)}
      />
    </form>
  );
}
